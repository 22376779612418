<!--
 * @version: 20220211
 * @Author: zgy
 * @Date: 2022-08-03 17:35:34
 * @LastEditors: zgy
 * @LastEditTime: 2022-08-05 13:52:55
 * @Description: description
-->
<template>
  <div class="page-box">
    <div class="item-list">
      <div class="label" @click="jumpPage('LKL', enrollState)">
        <div class="item-title ys-box">
          <span>开通产品</span>
          <span class="ys-tips">
            <template v-if="enrollState == 0">您还未开通间连1通道</template>
            <template v-if="enrollState == 1">间连1通道待人脸核身</template>
            <template v-if="enrollState == 2">间连1通道审核中</template>
            <template v-if="enrollState == 3">您已经开通间连1通道</template>
            <template v-if="enrollState == 4">间连1通道审核失败</template>
          </span>
        </div>
        <div>
          <div class="center open-btn">      
            <template v-if="enrollState == 0">去开通</template>
            <template v-if="enrollState == 1">待人脸核身</template>
            <template v-if="enrollState == 2">审核中</template>
            <template v-if="enrollState == 3">签约成功</template>
            <template v-if="enrollState == 4">审核失败</template>
          </div>
        </div>
      </div>
      <div class="label" @click="jumpPage('WX', applyStatus.WX)">
        <div class="rowCenter item-title">
          <img src="../assets/images/wx.png" mode="widthFix" class="item-icon store-icon" />
          <span>申请微信支付</span>
        </div>
        <div class="right-cont">
          <div class="status" :class="{'succe': applyStatus.WX == 3}">
            <template v-if="applyStatus.WX == 0">未开通</template>
            <template v-else-if="applyStatus.WX == 1">待人脸核身</template>
            <template v-else-if="applyStatus.WX == 2">审核中</template>
            <template v-else-if="applyStatus.WX == 3">签约成功</template>
            <template v-else-if="applyStatus.WX == 4">审核失败</template>
          </div>
          <div class="more"></div>    
        </div>
      </div>
      <div class="label" @click="jumpPage('ZFB', applyStatus.ZFB)">
        <div class="rowCenter item-title">
          <img src="../assets/images/zfb.png" mode="widthFix" class="item-icon store-icon" />
          <span>申请支付宝当面付</span>
        </div>
        <div class="right-cont">
          <div class="status" :class="{'succe': applyStatus.ZFB == 3}">
            <template v-if="applyStatus.ZFB == 0">未开通</template>
            <template v-else-if="applyStatus.ZFB == 1">待人脸核身</template>
            <template v-else-if="applyStatus.ZFB == 2">审核中</template>
            <template v-else-if="applyStatus.ZFB == 3">签约成功</template>
            <template v-else-if="applyStatus.ZFB == 4">审核失败</template>
          </div>
          <div class="more"></div>    
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs,nextTick } from 'vue'
import { mapGetters,useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { QueryProductAuditStatus } from '@/api/apply'
export default {
  name: 'applylist',
  computed: {
    ...mapGetters([
      'enrollState', //注册状态 100未查询  0: 未提交审核 1：资料已提交 2：已经人脸核身（审核中） 3：审核通过 4：审核失败
    ])
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const state = reactive({
      applyStatus: {
        WX: 0, //注册状态 100未查询  0: 未提交审核 1：资料已提交 2：已经人脸核身（审核中） 3：审核通过 4：审核失败
        ZFB: 0
      }
    })

    onMounted(() => {
      getAuditStatus('WX')
      getAuditStatus('ZFB')
    })

    // nextTick(() => {
    //   document.body.setAttribute('style', 'background:#f3f3f3')
    // })

    const getAuditStatus = type => {
      QueryProductAuditStatus(type).then(res => {
        state.applyStatus[type] = res.data.auditStatus
      })
    }

    const jumpPage = (type, status) => {
      if(status == 3){
        return;
      }
      router.push({ path: 'apply', query: { type: type, status: status }})
    }

    return {
      ...toRefs(state),
      jumpPage,
      getAuditStatus
    }
  }
};
</script>

<style lang="less" scoped>
@import '../assets/style/mixin';
.page-box{
  background-color: #f3f3f3;
  height: 100vh;
}
.center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-list{
  padding-top: 15px;
  .label{
    /* display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px #f2f2f2;
    height: 49px;
    padding: 0 15px;
    justify-content: space-between;
    background: #fff; */
      
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    margin: 0 15px 15px 15px;
    padding: 11px 15px;
    min-height: 27px;
    border-radius: 6px;
    
  }
  .item-title{
    font-size: 15px;
    color: #333333;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .ys-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .ys-tips{
    font-size: 12px;
    color: #999999;
    margin-top: 5px;
  }
  .open-btn{
    padding: 0 12px;
    height: 24px;
    background: #FFFFFF;
    border: 1px solid #FA3474;
    border-radius: 12px;
    font-size: 14px;
    color: #FA3474;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item-icon{
    width: 16px;
    margin-right: 9px;
  }
  .more{
    flex: none;
    width: 7px;
    height: 7px;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    transform: rotate(45deg);
    margin-left: 10px;
  }
}
.right-cont{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .status{
    font-size: 15px;
    color: #999999;
  }
  .succe{
    color: #FA3474;
  }
}

</style>